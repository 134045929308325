import { Box, Flex, HStack, Button, InputGroup, Image, Input, InputRightElement, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Avatar, Text, MenuItem, Menu, MenuButton, MenuList, Center, useToast, Portal, Select, WrapItem, Wrap } from '@chakra-ui/react'

import { useNavigate, useParams } from 'react-router-dom';
import ReactToPrint from "react-to-print";
import { useState, useRef, useEffect, ChangeEvent } from 'react';
import Loading from '../../components/Loading';
import giftCardService from '../../services/giftCard/giftCard.service';
import { IGiftCardRequestData, IGiftCardRequestRes } from '../../models/gift-cards/giftCardsRequest.model';
import { IGiftcardPreviewData, IGiftcardPreviewRes } from '../../models/gift-cards/preview.model';
import QRCode from 'react-qr-code';
import nocard from '../../img/Code.png'
import { BiSolidPrinter } from 'react-icons/bi';
import { RiShareForwardLine } from 'react-icons/ri';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import AdminRoutes from '../routes';
const PreviewGiftCard = () => {
    let navigate = useNavigate();
    const { request_id } = useParams();
    const toast = useToast()
    const [search, setSearch] = useState("");
    const [query, setQuery] = useState("");
    const componentRef = useRef<any>();
    const [previewGiftCard, setPreviewGiftCard] = useState<IGiftcardPreviewData[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [reload, setReload] = useState(false);
    useEffect(() => {

        giftCardService.previewCard(request_id)
            .then((data) => {
                const system_cardTypes: IGiftcardPreviewRes = data
                setPreviewGiftCard(system_cardTypes.data);
                setIsLoading(false)
            })
            .catch((err) => err)

    }, [query, reload]);



    const reloadData = () => {
        setReload(!reload);
    };

    const [templateImage, setImage] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleButtonClick = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    return (
        <>
            {
                isLoading ? <Loading /> :
                    <Box >
                        <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            py="15px"
                        >
                            <Box color="#2B2B2B" fontWeight="700" fontSize="24px">
                                Preview Card  : {request_id}
                            </Box>

                            <Box
                                mb="20px"
                                display="flex"
                                alignItems="center"
                                border="1px solid #616161"
                                padding={"5px 12px"}
                                width="fit-content"
                                borderRadius="5px"
                                cursor="pointer"
                            >
                                <HiArrowNarrowLeft color="#616161" fontSize="20px" />
                                <Text
                                    fontSize="13px"
                                    color="#616161"
                                    pl="5px"
                                    onClick={() => navigate(AdminRoutes.portal.giftCard)}
                                    fontWeight={'500'}
                                >
                                    Back 
                                </Text>
                            </Box>

                        </Flex>
                        <Box mx={'auto'} mt='40px' width={'600px'} boxShadow={'base'} borderRadius={'15px'} py='20px' bg={'white'} px={'40px'}>
                            {
                                templateImage ?
                                    <Box>
                                        <Flex justifyContent={'space-between'} pr={'25px'} py={'20px'}>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={handleImageChange}
                                                ref={inputRef}
                                                style={{ display: 'none' }}
                                            />
                                            <Button
                                                color={"white"}
                                                bg={"#011F78"}
                                                fontSize="12px"

                                                size={'sm'}
                                                onClick={handleButtonClick}
                                            >
                                                Change Template
                                            </Button>
                                            <ReactToPrint
                                                trigger={() => (
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                        color="#011F78"
                                                        fontWeight={700}
                                                        fontSize="14px"
                                                        border="1px solid #011F78"
                                                        px="20px"
                                                        py="5px"
                                                        borderRadius="8px"

                                                        cursor="pointer"
                                                    >
                                                        <RiShareForwardLine fontSize="20px" fontWeight={700} />
                                                        <Text pl="10px">Print</Text>
                                                    </Box>
                                                )}
                                                content={() => componentRef.current}
                                            />


                                        </Flex>
                                        <Wrap spacing={'20px'} ref={componentRef}>
                                            {
                                                templateImage && previewGiftCard.map((card, index) => (
                                                    <WrapItem key={index} px={'10px'} py={'10px'}>
                                                        <Flex>
                                                            <Flex width={'250px'} justify={'flex-end'} px={'10px'} py={'10px'} backgroundSize={'cover'} backgroundPosition={'center'} backgroundRepeat={'no-repeat'} height={'150px'} borderRadius={'10px'} backgroundImage={templateImage}>
                                                               <Box>
                                                               <QRCode
                                                                    value={card.qrString}
                                                                    bgColor={'#ffffff'}
                                                                    fgColor={'#000000'}
                                                                    size={50}
                                                                />
                                                                <Text fontSize={'10px'}>scan here</Text>
                                                               </Box>
                                                              
                                                            </Flex>
                                                            <Flex ml={'20px'} width={'250px'} alignItems={'center'} justifyContent={'center'} px={'10px'} py={'10px'} boxShadow={'base'} bg={'white'} height={'150px'} borderRadius={'5px'}>
                                                                <Text>{card.expiry}</Text>
                                                            </Flex>
                                                        </Flex>
                                                    </WrapItem>
                                                ))
                                            }

                                        </Wrap>
                                    </Box> : <Flex justifyContent="center" pb={'50px'}>
                                        <Box textAlign="center">
                                            <Box px={'110px'} mr={'20px'}>
                                                <Image width={'150px'} src={nocard} alt="logo" />
                                            </Box>
                                            <Text
                                                fontSize="18px"
                                                fontWeight={700}
                                                color="#353535"
                                                pt="20px"
                                                ml={'10px'}
                                            >
                                                Preview Card
                                            </Text>
                                            <Text
                                                fontWeight={400}
                                                fontSize="14px"
                                                color="#616161"
                                                width="350px"
                                                py="20px"
                                            >
                                                Kindly upload a template  to preview your card.
                                            </Text>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={handleImageChange}
                                                ref={inputRef}
                                                style={{ display: 'none' }}
                                            />
                                            <Button
                                                color={"white"}
                                                bg={"#011F78"}
                                                fontSize="13px"
                                                borderRadius="10px"
                                                mt="20px"
                                                width="182px"
                                                size={'sm'}
                                                onClick={handleButtonClick}
                                            >
                                                Upload Template
                                            </Button>
                                        </Box>
                                    </Flex>
                            }


                        </Box>

                    </Box>
            }
        </>
    )
}

export default PreviewGiftCard;