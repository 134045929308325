import { createBrowserRouter, RouterProvider } from "react-router-dom";
import './App.css';
import { AdminContainer } from './components/admin/AdminContainer';
import Login from './pages/auth/Login';
import ForgotPassword from './pages/auth/ForgotPassword';
import SetPassword from './pages/auth/SetPassword';
import EmailSent from './pages/auth/EmailSent';
import AdminRoutes from './pages/routes';
import User from "./pages/users/User";
import Roles from "./pages/roles/Roles";
import Clients from "./pages/clients/Clients";
import CardsByBatch from "./pages/cards/CardsByBatch";
import BatchList from "./pages/cards/BatchList";
import CardsType from "./pages/cards-type/CardType";
import NewCard from "./pages/cards/NewCard";
import GiftCardRequests from "./pages/gift-cards/GiftCardRequests";
import PreviewGiftCard from "./pages/gift-cards/PreviewRequest";

const router = createBrowserRouter([
  {
    path: AdminRoutes.Login,
    element: <Login />,
  },
  {
    path: AdminRoutes.forgotPassword,
    element: <ForgotPassword />,
  },
  {
    path: AdminRoutes.emailSent,
    element: <EmailSent />,
  },
  {
    path: AdminRoutes.setPassword,
    element: <SetPassword />,
  },


  {
    path: '/card-management/',
    element: <AdminContainer />,

    
    children: [
      {
        path: AdminRoutes.portal.dashboard,
        element: <User />,
      },
      {
        path: AdminRoutes.portal.clients,
        element: <Clients />,
      },
      {
        path: AdminRoutes.portal.roles,
        element: <Roles />,
      },
      {
        path: AdminRoutes.portal.cards,
        element: <BatchList />,
      },
      {
        path: AdminRoutes.portal.giftCard,
        element: <GiftCardRequests />,
      },
      {
        path: AdminRoutes.portal.previewCard,
        element: <PreviewGiftCard />,
      },
      {
        path: AdminRoutes.portal.newCard,
        element: <NewCard />,
      },
      {
        path: AdminRoutes.portal.cardByBatch,
        element: <CardsByBatch />,
      },
      {
        path: AdminRoutes.portal.cardsType,
        element: <CardsType />,
      },



    ],
  },
]);
function App() {

  return <RouterProvider router={router} />;

}

export default App;
