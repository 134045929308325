

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Text,
    Box,
    Button,
    useToast,
    Flex,
    HStack,
    Checkbox,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import cardTypeService from "../../services/cardType/card-type.service";
import { ICardsTypeData } from "../../models/cards/cards-type.model";

export const CardsTypeModal: React.FC<any> = ({ isOpencardTypess, onClosecardTypess, iscardTypess = false, reload, updatecardTypesss ,cardTypesss,cardTypesssId}: { isOpencardTypess: any, onClosecardTypess: any, iscardTypess: boolean, reload: any, updatecardTypesss: any, cardTypesssId:string[], cardTypesss:ICardsTypeData[] }) => {
    const toast = useToast();
    const [cardTypessss, setcardTypesss] = useState<any[]>([])
    const [selectedcardTypess, setSelectedcardTypesss] = useState<ICardsTypeData[]>();
    const [selectedcardTypessId, setSelectedcardTypesssId] = useState<string[]>();
    useEffect(() => {
        setSelectedcardTypesss([...cardTypesss])
        setSelectedcardTypesssId([...cardTypesssId])
        cardTypeService.getCardTypeList()
        .then((data) => {
                setcardTypesss(data.data)
             
            })
            .catch((err) => err);
    }, []);

    const addcardTypesss = (role: string, checked: boolean) => {
        let cardTypesssId: any[] = [];
        let cardTypesss: any[] = [];
        selectedcardTypessId !== undefined ? cardTypesssId = [...selectedcardTypessId] : cardTypesssId = [];
        selectedcardTypess !== undefined ? cardTypesss = [...selectedcardTypess] : cardTypesss = [];
        if (checked) {
            cardTypesssId.push(role)
            const selectedcardTypess = cardTypessss.filter((each :ICardsTypeData) => role == each.cardTypeID?.toString())
            console.log(selectedcardTypess)
            const merged =[...cardTypesss,...selectedcardTypess]
            setSelectedcardTypesssId(cardTypesssId)
            setSelectedcardTypesss([...merged])
        } else if (!checked) {
            const ind = cardTypesssId.indexOf(role)
            console.log(ind,'index')
            cardTypesssId.splice(ind, 1)
            cardTypesss.splice(ind, 1)
            setSelectedcardTypesssId(cardTypesssId)
            setSelectedcardTypesss(cardTypesss)
        }
    
    }

    const updatecardTypess = () => {
        updatecardTypesss(selectedcardTypess,selectedcardTypessId)

        toast({
            title: "cardTypess",
            description: "cardTypesss added successfully",
            status: "success",
        })
        onClosecardTypess()
    }

    const closeModal = () => {
        setSelectedcardTypesssId([])
        setSelectedcardTypesss([])
        onClosecardTypess()
    }




return (
    <>
        <Modal
            isOpen={isOpencardTypess && iscardTypess}
            onClose={closeModal}
            scrollBehavior="outside"
            closeOnOverlayClick={true}
            size="md"
            isCentered
        >
            <ModalOverlay />
            <ModalContent borderRadius={5} px={3}>
                <ModalHeader>
                    <Flex justifyContent='cardTypess'>
                        <Box>
                            <Flex justifyContent='cardTypess'>
                                <Text  color="#011F78" fontSize="20px" fontWeight='700'>
                                    Add Card Types
                                </Text>
                            </Flex>
                            <Text color='#011F78' fontWeight='500' fontSize="14px">NB: You must select at least one card type</Text>
                        </Box>
                    </Flex>

                </ModalHeader>
                <ModalCloseButton size="sm" />

                <ModalBody>
                    <Box mt={4}>


                        {
                            cardTypessss.map((each, index) =>
                                <HStack key={index} spacing={4} mb='15px'>
                                    <Checkbox colorScheme="blue" name={each.cardTypeName} isChecked={selectedcardTypessId && selectedcardTypessId.includes(each.cardTypeID.toString())} value={each.cardTypeID} onChange={(e) => addcardTypesss(e.target.value, e.target.checked)} />
                                    <Text ml={3} color="#9E9E9E" fontSize='15px'>
                                        {each.cardTypeName}
                                    </Text>

                                </HStack>)
                        }


                        <Flex mt={12} justifyContent="end">
                            <HStack>
                                <Button size="sm" px={6} fontSize={12} onClick={() => closeModal()}>
                                    Cancel
                                </Button>
                                <Button  color={"white"}
                                   
                                    bg={"#011F78"} type="submit"
                                    size="sm" px={6} onClick={() => { updatecardTypess() }} fontSize={12} >SAVE </Button>
                            </HStack>
                        </Flex>

                    </Box>


                </ModalBody>

        
            </ModalContent>
        </Modal>
    </>
);
  };
