import getService from "../crud-requests/getService";
import postService from "../crud-requests/postService";



const CONFIG_API_URL = process.env.REACT_APP_API_BASE_URL ;

class RoleService {
    async getRoleList() {
      const response = await getService.get(
        `${CONFIG_API_URL}Role/List`,    
      );        
      return response.data;
    }   
    async createRole(data: any) {
        const response = await postService.post(
          `${CONFIG_API_URL}Role/CreateRole`,
          data
        );
        return response.data;
      }  
    async getRoleDetail(key : string | undefined) {
        const response = await getService.get(
          `${CONFIG_API_URL}Role/Detail/${key}`,    
        );        
        return response.data;
      }   
      async getRolePermission(key : string | undefined) {
        const response = await getService.get(
          `${CONFIG_API_URL}Role/RolePermissions​/${key}`,    
        );        
        return response.data;
      }   
  
      
      async addPermission(data: any) {
        const response = await postService.post(
          `${CONFIG_API_URL}Role/AddPermissions`,
          data
        );
        return response.data;
      }  
  }
  
  export default new RoleService();