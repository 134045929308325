import { Box, Flex, HStack, Button, InputGroup, Input, InputRightElement, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Avatar, Text, MenuItem, Menu, MenuButton, MenuList, Center, useToast, Portal, Select } from '@chakra-ui/react'

import { useNavigate } from 'react-router-dom';
import { RiSearch2Line } from 'react-icons/ri';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { NumericFormat } from 'react-number-format';
import { usePagination } from '@ajna/pagination';
import { useState, useRef, useEffect, ChangeEvent } from 'react';
import { Ipage } from '../../models/auth/login.model';
import userService from '../../services/user/user.service';
import { CreateUserModal } from '../../components/users/createUserModal';
import { IUserData, IUserRes } from '../../models/users/users.model';
import { getFullDate } from '../../utils/getDate';
import { CreateRole } from '../../components/roles/CreateRole';
import rolesService from '../../services/roles/roles.service';
import { IRolesData, IRolesRes } from '../../models/roles/roles.model';
import cardMasterService from '../../services/cardMaster/card-master.service';
import { ICardData, ICardRes } from '../../models/cards/cards.model';
import AdminRoutes from '../routes';
import Loading from '../../components/Loading';
import PaginationData from '../../components/PaginationData';

const BatchList = () => {
    let navigate = useNavigate();
    const toast = useToast()
    const [search, setSearch] = useState("");
    const [query, setQuery] = useState("");
    const [page, setPage] = useState<Ipage>({ page_total: 0, total: 0, page_count: 0 });
    const [systemcardsList, setSystemcardsList] = useState<ICardData[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [reload, setReload] = useState(false);
    const [isEditcards, setisEditcards] = useState(false);
    const [iscards, setiscards] = useState(false);
    const [cards, setcards] = useState<any>();
    const [cardsName, setcardsName] = useState<string>();
    const [isOpenAlert, setIsOpen] = useState(false);
    const [isSearchEmpty, setIsSearchEmpty] = useState(false);
    const [fileteredcardList, setFilteredcardList] = useState<ICardData[]>([]);
    const onCloseAlert = () => setIsOpen(false);
  
    useEffect(() => {
        // let url = `?pageNumber=${currentPage}&pageSize=${100}&searchString=${query}`
        cardMasterService.getCardMasterBatchList()
            .then((data) => {
                const system_cards: ICardRes = data
                //setPage({ page_total: system_param.pageSize, total: system_param.totalCount, page_count: system_param.totalPages })
                setSystemcardsList(system_cards.data);
                setFilteredcardList(system_cards.data);
                setIsLoading(false)
            })
            .catch((err) => err)

    }, [ query, reload]);
    const viewCards = (batch_id: string) => {
        navigate(AdminRoutes.portal.cardByBatchLink(batch_id));

    }

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");
    const filteredCardBatchList = systemcardsList.filter((batch) =>
        batch.batchID.toString().toLowerCase().includes(searchQuery.toString()) || batch.cardType.toString().toLowerCase().includes(searchQuery.toString())

    );
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredCardBatchList.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredCardBatchList.length / itemsPerPage);


    const handleItemsPerPageChange = (newItemsPerPage: number) => {
        setItemsPerPage(newItemsPerPage);
        setCurrentPage(1);
    };

    const handlePageChange = (pageNumber: number) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };
    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when the search query changes
    };
    
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (search.length > 0) {
                setQuery(search);
            } else {
                setQuery("");
            }
        }, 700);

        return () => clearTimeout(delayDebounceFn)
    }, [search])
    const opencards = () => {
        navigate(AdminRoutes.portal.newCard)

    };
    const openEditcards = (param: any) => {
        setcards(param)
        setisEditcards(true)
        setiscards(false)
        setIsOpen(false)
    };

    const reloadData = () => {
        setReload(!reload);
    };

 
    return (
        <>
          {
                isLoading ? <Loading /> :
            <Box >
                <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    py="15px"
                >
                    <Box color="#2B2B2B" fontWeight="800" fontSize="27px">
                      Card Batch List
                    </Box>
                    <HStack>
                        <Button
                            cursor="pointer"
                            color={"white"}
                            bg={"#011F78"}
                            type="submit"
                            fontSize={'13px'}
                            onClick={() => { opencards() }}
                        >
                            Create Cards
                        </Button>
                    </HStack>
                </Flex>
                <Flex py='15px' justifyContent=''>
                    <InputGroup bg="white" width="30%" mr='20px'>
                        <Input height='40px' placeholder="Search input here" value={searchQuery}
                                    onChange={handleSearchChange} fontSize={12} size="sm" />
                        <InputRightElement pointerEvents="none" children={<RiSearch2Line color="blue" size="0.9rem" />} />
                    </InputGroup>

                </Flex>
                <Box width={'100%'}>
                    <TableContainer>
                        <Table  size={'sm'} >
                            <Thead py={'15px'}>
                                <Tr bg="white" py={'15px'} >
                                    <Td py={'15px'} textTransform={'capitalize'}>Batch ID</Td>
                                    <Td textTransform={'capitalize'}>Card Type </Td>
                                    <Td textTransform={'capitalize'}>Type Details</Td>
                                    <Td textTransform={'capitalize'}>Actions</Td>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {
                                    currentItems && currentItems.length > 0 && (
                                        currentItems.map((card, index) => (
                                            <Tr bg="white" key={index}>
                                                <Td>
                                                    <Text fontSize={'13px'} >{card.batchID}</Text>
                                                </Td>
                                                <Td>
                                                    <Text fontSize={'13px'} >{card.cardType}</Text>
                                                </Td>

                                                <Td>
                                                    <Text fontSize={'13px'} >{card.totalCard}</Text>
                                                </Td>
                                                <Td>
                                                    <Button
                                                        cursor="pointer"
                                                        color={"white"}
                                                        bg={"#011F78"}
                                                        size={'sm'}
                                                        fontSize={'13px'}
                                                        onClick={() => { viewCards(card.batchID.toString()) }}
                                                    >
                                                        View
                                                    </Button>
                                                </Td>
                                            </Tr>
                                        ))
                                    )
                                }


                            </Tbody>
                        </Table>
                    </TableContainer>    
                          <Flex justifyContent={'flex-end'} mt={'10px'}>
                            <HStack mr={'5px'}>
                                <Text fontWeight={400} fontSize={'10px'}>Rows per page :</Text>
                                <Select
                                    variant="outline"
                                    size="sm"
                                    width={'70px'}
                                    value={itemsPerPage}
                                    onChange={(e) => handleItemsPerPageChange(Number(e.target.value))}
                                    fontSize={12}
                                >
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                </Select>

                            </HStack>
                            <PaginationData
                                currentPage={currentPage}
                                totalPages={totalPages}
                                handlePageChange={handlePageChange}
                            />
                        </Flex>

                </Box>
                {iscards && (
                    <Portal>
                        {" "}
                        <CreateRole
                            isOpenRole={iscards}
                            onClosed={setiscards}
                            iscards={iscards}
                            reload={reloadData}
                        />{" "}
                    </Portal>
                )}
                
            </Box>
}
        </>
    )
}

export default BatchList;