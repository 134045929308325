import React from "react";
import PaginationButton from "./PaginationButton";
import { Text, Flex } from "@chakra-ui/react";

function PaginationData({ currentPage, totalPages, handlePageChange }) {
  const visiblePages = 10;
  const startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
  const endPage = Math.min(totalPages, startPage + visiblePages - 1);

  const pages = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

  return (
    <>
      <Flex align={'center'} alignContent={"flex-end"} justifyContent={'flex-end'}>

        <PaginationButton
          label="Previous"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        />

        {startPage > 1 && (
          <>
            <PaginationButton
              label={1}
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            />
            {startPage > 2 && <Text>...</Text>}
          </>
        )}

        {pages.map((page) => (
          <PaginationButton
            key={page}
            label={page}
            onClick={() => handlePageChange(page)}
            disabled={currentPage === page}
          />
        ))}

        {endPage < totalPages && (
          <>
            {endPage < totalPages - 1 && <Text>...</Text>}
            <PaginationButton
              label={totalPages}
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
            />
          </>
        )}

        <PaginationButton
          label="Next"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        />

        <Text fontWeight={'400'} fontSize={'10px'}>
          Page {currentPage} of {totalPages}
        </Text>
      </Flex>
    </>
  );
}

export default PaginationData;
