import { Box, Flex, HStack, Button, InputGroup, Input, InputRightElement, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Avatar, Text, MenuItem, Menu, MenuButton, MenuList, Center, useToast, Portal, Select } from '@chakra-ui/react'

import { useNavigate } from 'react-router-dom';
import { RiSearch2Line } from 'react-icons/ri';
import { BsCheckLg, BsThreeDotsVertical } from 'react-icons/bs';
import { NumericFormat } from 'react-number-format';
import { usePagination } from '@ajna/pagination';
import { useState, useRef, useEffect, ChangeEvent } from 'react';
import { Ipage } from '../../models/auth/login.model';
import userService from '../../services/user/user.service';
import { CreateUserModal } from '../../components/users/createUserModal';
import { IUserData, IUserRes } from '../../models/users/users.model';
import { getDateAndTime, getFullDate } from '../../utils/getDate';
import { CreateRole } from '../../components/roles/CreateRole';
import rolesService from '../../services/roles/roles.service';
import { IRolesData, IRolesRes } from '../../models/roles/roles.model';
import cardTypeService from '../../services/cardType/card-type.service';
import { FaTimes } from 'react-icons/fa';
import { CreateCardTypeModal } from '../../components/card-type/CreateCardTypeModal';
import Loading from '../../components/Loading';
import PaginationData from '../../components/PaginationData';
import { EditCardTypeModal } from '../../components/card-type/EditCardTypeModal';
import giftCardService from '../../services/giftCard/giftCard.service';
import { IGiftCardRequestData, IGiftCardRequestRes } from '../../models/gift-cards/giftCardsRequest.model';
import AdminRoutes from '../routes';

const GiftCardRequests = () => {
    let navigate = useNavigate();
    const toast = useToast()
    const [search, setSearch] = useState("");
    const [query, setQuery] = useState("");

    const [giftCardRequests, setgiftCardRequests] = useState<IGiftCardRequestData[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [reload, setReload] = useState(false);
    const [isEditcardTypes, setisEditcardTypes] = useState(false);
    const [iscardTypes, setiscardTypes] = useState(false);
    const [cardTypes, setcardTypes] = useState<any>();

    useEffect(() => {
        // let url = `?pageNumber=${currentPage}&pageSize=${100}&searchString=${query}`
        giftCardService.getRecentCardRequest()
            .then((data) => {
                const system_cardTypes: IGiftCardRequestRes = data
                //setPage({ page_total: system_param.pageSize, total: system_param.totalCount, page_count: system_param.totalPages })
                setgiftCardRequests(system_cardTypes.data);
                setIsLoading(false)
            })
            .catch((err) => err)

    }, [query, reload]);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");
    const filteredgiftCardRequests: IGiftCardRequestData[] = giftCardRequests ? giftCardRequests.filter((batch) =>
        batch.giftRequestCustomerID.toString().toLowerCase().includes(searchQuery.toString()) || batch.giftRequestID.toString().toLowerCase().includes(searchQuery.toString())

    ) : [];

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredgiftCardRequests.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredgiftCardRequests.length / itemsPerPage);


    const handleItemsPerPageChange = (newItemsPerPage: number) => {
        setItemsPerPage(newItemsPerPage);
        setCurrentPage(1);
    };

    const handlePageChange = (pageNumber: number) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };
    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when the search query changes
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (search.length > 0) {
                setQuery(search);
            } else {
                setQuery("");
            }
        }, 700);

        return () => clearTimeout(delayDebounceFn)
    }, [search])
    const opencardTypes = () => {
        setisEditcardTypes(false)
        setiscardTypes(true)

    };
    const openEditcardTypes = (param: any) => {
        setcardTypes(param)
        setisEditcardTypes(true)
        setiscardTypes(false)
    };

    const reloadData = () => {
        setReload(!reload);
    };
    const previewCard = (id: string) => {
        navigate(AdminRoutes.portal.previewCardLink(id));

    }


    return (
        <>
            {
                isLoading ? <Loading /> :
                    <Box >
                        <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            py="15px"
                        >
                            <Box color="#2B2B2B" fontWeight="800" fontSize="27px">
                                Gift Cards Requests
                            </Box>

                        </Flex>
                        <Flex py='15px' justifyContent=''>
                            <InputGroup bg="white" width="30%" mr='20px'>
                                <Input height='40px' placeholder="Search input here" value={searchQuery}
                                    onChange={handleSearchChange} fontSize={12} size="sm" />
                                <InputRightElement pointerEvents="none" children={<RiSearch2Line color="green" size="0.9rem" />} />
                            </InputGroup>

                        </Flex>
                        <Box width={'100%'}>
                            <TableContainer>
                                <Table size={'sm'}>
                                    <Thead py={'15px'}>
                                        <Tr bg="white" py={'15px'} >
                                            <Td py={'15px'} textTransform={'capitalize'}> Customer ID</Td>
                                            <Td textTransform={'capitalize'}>Card Type </Td>
                                            <Td textTransform={'capitalize'}>No of Cards  </Td>
                                            <Td textTransform={'capitalize'}>Card Amount</Td>
                                            <Td textTransform={'capitalize'}>Total Amount</Td>
                                            <Td textTransform={'capitalize'}>Print Cost</Td>
                                            <Td textTransform={'capitalize'}>Date Requested</Td>
                                            <Td textTransform={'capitalize'}>Actions</Td>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {
                                            currentItems ? currentItems.length > 0 ? (
                                                currentItems.map((card, index: number) => (
                                                    <Tr bg="white" key={index}>
                                                        <Td>
                                                            <Text fontSize={'13px'} >{card.giftRequestCustomerID}</Text>
                                                        </Td>
                                                        <Td>
                                                            <Text fontSize={'13px'} >{card.cardType}</Text>
                                                        </Td>
                                                        <Td>
                                                            <Text fontSize={'13px'} >{card.cardCount}</Text>
                                                        </Td>
                                                        <Td>
                                                            {
                                                                card.cardAmount ? <Text fontSize={'13px'} >
                                                                    <NumericFormat
                                                                        value={card.cardAmount}
                                                                        displayType='text'
                                                                        thousandSeparator={true}
                                                                        prefix={'₦'} /></Text> : <Text fontSize={'13px'} >Nil</Text>
                                                            }
                                                        </Td>
                                                        <Td>
                                                            {
                                                                card.totalAmount ? <Text fontSize={'13px'} >
                                                                    <NumericFormat
                                                                        value={card.totalAmount}
                                                                        displayType='text'
                                                                        thousandSeparator={true}
                                                                        prefix={'₦'} /></Text> : <Text fontSize={'13px'} >Nil</Text>
                                                            }
                                                        </Td>
                                                        <Td>
                                                            {
                                                                card.printCost ? <Text fontSize={'13px'} >
                                                                    <NumericFormat
                                                                        value={card.printCost}
                                                                        displayType='text'
                                                                        thousandSeparator={true}
                                                                        prefix={'₦'} /></Text> : <Text fontSize={'13px'} >Nil</Text>
                                                            }
                                                        </Td>

                                                        <Td>
                                                            <Text fontSize={'14px'} >{getDateAndTime(card.createdDated)}</Text>

                                                        </Td>

                                                        <Td>
                                                            <HStack>
                                                                <Button
                                                                    cursor="pointer"
                                                                    color={"white"}
                                                                    bg={"#011F78"}
                                                                    size={'sm'}
                                                                    fontSize={'13px'}
                                                                    onClick={() => { previewCard(card.giftRequestID) }}
                                                                >
                                                                    Preview
                                                                </Button>

                                                            </HStack>
                                                        </Td>
                                                    </Tr>
                                                ))
                                            ) : <Text textAlign={'center'}>No data</Text> : <Text textAlign={'center'}>No Gift card created</Text>
                                        }


                                    </Tbody>
                                </Table>
                            </TableContainer>
                            <Flex justifyContent={'flex-end'} mt={'10px'}>
                                <HStack mr={'5px'}>
                                    <Text fontWeight={400} fontSize={'10px'}>Rows per page :</Text>
                                    <Select
                                        variant="outline"
                                        size="sm"
                                        width={'70px'}
                                        value={itemsPerPage}
                                        onChange={(e) => handleItemsPerPageChange(Number(e.target.value))}
                                        fontSize={12}
                                    >
                                        <option value={10}>10</option>
                                        <option value={25}>25</option>
                                        <option value={50}>50</option>

                                    </Select>

                                </HStack>
                                <PaginationData
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    handlePageChange={handlePageChange}
                                />

                            </Flex>

                        </Box>
                        {iscardTypes && (
                            <Portal>
                                {" "}
                                <CreateCardTypeModal
                                    isOpencardType={iscardTypes}
                                    onClosed={setiscardTypes}
                                    iscardTypes={iscardTypes}
                                    reload={reloadData}
                                />{" "}
                            </Portal>
                        )}
                        {isEditcardTypes && (
                            <Portal>
                                {" "}
                                <EditCardTypeModal
                                    isOpencardType={isEditcardTypes}
                                    onClosed={setisEditcardTypes}
                                    isEditcardTypes={isEditcardTypes}
                                    reload={reloadData}
                                    card={cardTypes}
                                />{" "}
                            </Portal>
                        )}
                    </Box>
            }
        </>
    )
}

export default GiftCardRequests;