
import { ILoginData, ILoginDetails } from "../../models/auth/login.model";
import getService from "../crud-requests/getService";
import postService from "../crud-requests/postService";



const CONFIG_API_URL = process.env.REACT_APP_API_BASE_URL ;

export const destroyUserSession = () => {
  sessionStorage.removeItem("card_user_token");
  sessionStorage.removeItem("card_user_user");
  sessionStorage.removeItem('UserFromToken')
};

export const getLocalUserProfile = (): ILoginDetails | null => {
  let user = sessionStorage.getItem("card_user_user");
  if (user) {
    let userModel: ILoginDetails = JSON.parse(user);
    return userModel;
  }
  return null;
};

export const getAuthToken = (): string | null => {
  const token = sessionStorage.getItem("card_user_token")?.toString();
  console.log(token,'token')
   if (token) {
    return token;
  }
  return "";
};
export const getRefreshToken = (): string | null => {
  let refreshToken = sessionStorage.getItem("card_user_refresh");
  if (refreshToken) {
    return refreshToken
  }
  return "";
};

class AuthService {
 
  async login(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}Account/Authenticate`,
      data
    );
    return response.data;
  }

  async accessToken(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}Account/AccessToken`,
      data
    );
    return response.data;
  }
  async forgot_password(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}Account/ForgotPassword`,
      data
    );

    return response.data;
  }
  async resetPassword(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}Account/ResetPassword`,
      data
    );
    return response.data;
  } 
  async verifyCode(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}Account/VerifyCode`,
      data
    );
    return response.data;
  } 
  async changeDefaultPassword(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}Account/ChangeDefaultPassword`,
      data
    );
    return response.data;
  } 

}

export default new AuthService();
