import getService from "../crud-requests/getService";
import postService from "../crud-requests/postService";
import putService from "../crud-requests/putService";



const CONFIG_API_URL = process.env.REACT_APP_API_BASE_URL;

class GiftCardService {
    async getRecentCardRequest() {
        const response = await getService.get(
            `${CONFIG_API_URL}GiftCardAdmin/RecentCardRequest`,
        );
        return response.data;
    }
    async getCustomerCardRequest(key: string | undefined) {
        const response = await getService.get(
            `${CONFIG_API_URL}GiftCardAdmin/CardRequests/${key}`,
        );
        return response.data;
    }
    async getRequestDetails(key: string | undefined) {
        const response = await getService.get(
            `${CONFIG_API_URL}GiftCardAdmin/CardRequest/Details/${key}`,
        );
        return response.data;
    }
    async previewCard(key: string | undefined) {
        const response = await getService.get(
            `${CONFIG_API_URL}GiftCardAdmin/CardRequest/Preview/${key}`,
        );
        return response.data;
    }
 
    
    async printCard(data: any) {
        const response = await putService.put(
            `${CONFIG_API_URL}GiftCardAdmin/PrintCards`,
            data
        );
        return response.data;
    }
}

export default new GiftCardService();