import getService from "../crud-requests/getService";
import postService from "../crud-requests/postService";
import putService from "../crud-requests/putService";



const CONFIG_API_URL = process.env.REACT_APP_API_BASE_URL;

class CardTypeService {
    async getCardTypeList() {
        const response = await getService.get(
            `${CONFIG_API_URL}CardType/List`,
        );
        return response.data;
    }
    async getCardTypeDetail(key: string | undefined) {
        const response = await getService.get(
            `${CONFIG_API_URL}CardType/Detail/${key}`,
        );
        return response.data;
    }
    
    async createCardType(data: any) {
        const response = await postService.post(
            `${CONFIG_API_URL}CardType/CreateCardType`,
            data
        );
        return response.data;
    }
    async editCardType(data: any) {
        const response = await putService.put(
            `${CONFIG_API_URL}CardType/Edit`,
            data
        );
        return response.data;
    }
}

export default new CardTypeService();