import getService from "../crud-requests/getService";
import postService from "../crud-requests/postService";
import putService from "../crud-requests/putService";



const CONFIG_API_URL = process.env.REACT_APP_API_BASE_URL;

class ClientAccessService {
    async getClientAccessList() {
        const response = await getService.get(
            `${CONFIG_API_URL}ClientAccess/Clients`,
        );
        return response.data;
    }
    async getClientAccessDetail(key: string | undefined) {
        const response = await getService.get(
            `${CONFIG_API_URL}ClientAccess/Client/${key}`,
        );
        return response.data;
    }
    
    async createClientAccess(data: any) {
        const response = await postService.post(
            `${CONFIG_API_URL}ClientAccess/CreateClient`,
            data
        );
        return response.data;
    }
    async disableClientAccess(data: any,id : string | undefined) {
        const response = await putService.put(
            `${CONFIG_API_URL}ClientAccess/Disable/${id}`,
            data
        );
        return response.data;
    }
}

export default new ClientAccessService();