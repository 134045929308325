import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    Box,
    useToast,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Flex,
    Button,
    HStack,
    Textarea,
    Center,
    InputGroup,
    InputLeftElement,
    InputRightElement,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import userService from "../../services/user/user.service";
import { BiHide, BiShow } from "react-icons/bi";
import { MdLock } from "react-icons/md";
import rolesService from "../../services/roles/roles.service";
import { IRolesRes } from "../../models/roles/roles.model";
import Select from 'react-select';
import { IUserData } from "../../models/users/users.model";
export const EditUserModal: React.FC<any> = ({
    isOpenUser,
    onClosed,
    isEditUser = false,
    reload,
    user
}: {
    isEditUser: boolean;
    isOpenUser: any;
    onClosed: any;
    reload: any;
    user: IUserData
}) => {
    const toast = useToast();
    const cancelRef = useRef().current;
    const [show, setShow] = useState(false)
    const [roles, setRoles] = useState<any>()
    const [isLoading, setIsLoading] = useState(true)
    const [selectedRole, setselectedRole] = useState<any>(null);
    const [rolesList, setrolesList] = useState<any[]>();
    const handleClick = () => setShow(!show)

    useEffect(() => {
        rolesService.getRoleList()
            .then((data) => {
                let rolesArray: any[] = []
                const roles: IRolesRes = data
                roles.data.forEach(element => {
                    rolesArray.push({ value: element.roleCode, label: element.roleName })
                });
                setselectedRole(user.role)
                setrolesList(rolesArray)
                setIsLoading(false)
            })
            .catch((err) => err)
        if (user) {
            formik.setValues(
                {
                    username: user.username,
                    email: user.email,
                    firstName: user.firstName,
                    lastName: user.lastName,

                }
            )
        }
    }, [reload]);
    const handleChangeRoles = (selectedRole: any) => {
        setselectedRole(selectedRole);
    };
    const formik = useFormik({
        validate: (values: any) => {
            const errors: any = {};
            if (!values.username) {
                errors.username = "username is required";
            }
            if (!values.email) {
                errors.email = "email is required";
            }

            return errors;
        },
        initialValues: {
            username: "",
            email: "",
            firstName: "",
            lastName: "",


        },
        onSubmit: async (values) => {
            try {
                const new_param: any = {
                    username: values.username,
                    email: values.email,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    role: selectedRole.value
                }

                const create_User: any = await userService.editUser(new_param)
                console.log(create_User.data)
                if (create_User.data) {
                    toast({
                        title: "Update User",
                        description: "User updated successfully",
                        status: "success",
                    });
                    onClosed(!isEditUser)
                    reload()
                } else {
                    toast({
                        title: "Update User",
                        description: `${create_User.messages.$values[0]}`,
                        status: "error",
                    });
                }
            } catch (error: any) {
                toast({
                    title: "Update User",
                    description: `${error}`,
                    status: "error",
                });
            }

        },
    });

    return (
        <>
            <Modal
                isOpen={isOpenUser && isEditUser}
                onClose={onClosed}
                scrollBehavior="outside"
                closeOnOverlayClick={true}
                size="lg"
            >
                <ModalOverlay />
                <ModalContent borderRadius={5} px={6}>
                    <ModalHeader>
                        {
                            <Text
                                fontSize={'18px'}
                                pb={3}
                                textTransform="capitalize"


                            >
                                Edit User
                            </Text>
                        }
                    </ModalHeader>
                    <ModalCloseButton size="sm" />
                    <ModalBody pb={'30px'}>
                        <Box mt={2}>
                            <form onSubmit={formik.handleSubmit}>
                                <HStack>
                                    <FormControl
                                        mt={3}
                                        isInvalid={
                                            formik.errors.username && formik.touched.username ? true : false
                                        }
                                    >
                                        <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                            First Name
                                        </FormLabel>
                                        <Input
                                            type="text"
                                            id="firstName"
                                            name="firstName"
                                            placeholder="Enter first name"
                                            onChange={formik.handleChange}
                                            fontSize={'12px'}
                                        />
                                        <FormErrorMessage fontSize={12}>
                                            {formik.errors.firstName}
                                        </FormErrorMessage>
                                    </FormControl>
                                    <FormControl
                                        mt={3}
                                        isInvalid={
                                            formik.errors.username && formik.touched.username ? true : false
                                        }
                                    >
                                        <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                            Last Name
                                        </FormLabel>
                                        <Input
                                            type="text"
                                            id="lastName"
                                            name="lastName"
                                            placeholder="Enter last name"
                                            onChange={formik.handleChange}
                                            fontSize={'12px'}
                                        />
                                        <FormErrorMessage fontSize={12}>
                                            {formik.errors.lastName}
                                        </FormErrorMessage>
                                    </FormControl>
                                </HStack>
                                <HStack>
                                    <FormControl
                                        mt={3}
                                        isInvalid={
                                            formik.errors.username && formik.touched.username ? true : false
                                        }
                                    >
                                        <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                            Username
                                        </FormLabel>
                                        <Input
                                            type="text"
                                            id="username"
                                            name="username"
                                            placeholder="Enter username"
                                            onChange={formik.handleChange}
                                            fontSize={'12px'}
                                        />
                                        <FormErrorMessage fontSize={12}>
                                            {formik.errors.username}
                                        </FormErrorMessage>
                                    </FormControl>
                                    <FormControl mt={3}  >
                                        <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                            Role
                                        </FormLabel>
                                        <Select
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: state.isFocused ? '#D6D6D6' : '#D6D6D6',
                                                    fontSize: '12px'
                                                }),
                                            }}
                                            value={selectedRole}
                                            onChange={handleChangeRoles}
                                            options={rolesList}
                                            isSearchable={true}
                                            placeholder={'select role...'}
                                        />
                                    </FormControl>
                                </HStack>


                                <HStack>
                                    <FormControl
                                        mt={3}
                                        isInvalid={
                                            formik.errors.email && formik.touched.email ? true : false
                                        }
                                    >
                                        <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                            Email
                                        </FormLabel>
                                        <Input
                                            type="email"
                                            id="email"
                                            name="email"
                                            placeholder="Enter email"
                                            onChange={formik.handleChange}
                                            fontSize={'12px'}
                                        />
                                        <FormErrorMessage fontSize={12}>
                                            {formik.errors.email}
                                        </FormErrorMessage>
                                    </FormControl>

                                </HStack>

                                <Flex mt={12} justifyContent={'space-between'} >
                                    <Button
                                        px={6}
                                        colorScheme="blue"
                                        fontSize={'14px'}
                                        variant={'outline'}
                                        ref={cancelRef}
                                        width={'49%'}
                                        onClick={() => onClosed()}
                                    >
                                        Cancel
                                    </Button>
                                    <Button

                                        type="submit"
                                        isLoading={formik.isSubmitting}
                                        color={"white"}
                                        width={'49%'}
                                        bg={"#011F78"}
                                        px={6}
                                        fontSize={'14px'}
                                    >
                                        Update User
                                    </Button>


                                </Flex>
                            </form>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
