import { Flex, Container, Box, Button, HStack, Img, Text, useToast, useDisclosure, Portal, Stack, FormControl, Input } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { HiArrowNarrowLeft } from "react-icons/hi";
import collo from '../../img/savings.png'
import pocket from '../../img/icon.png'
import gift from '../../img/wallet_phone.png'
import AdminRoutes from "../routes";
import { ILoginData } from "../../models/auth/login.model";
import { getLocalUserProfile } from "../../services/auth/auth.service";
import { IRegRes } from "../../models/auth/registration.model";
import cardTypeService from "../../services/cardType/card-type.service";
import Loading from "../../components/Loading";
import { CardsTypeModal } from "../../components/card-type/cardsTypeModal";
import { ICardGenerate, ICardsTypeData, ICardsTypeRes } from "../../models/cards/cards-type.model";
import cardMasterService from "../../services/cardMaster/card-master.service";

const NewCard = () => {
    const navigate = useNavigate()
    const toast = useToast()
    const [cardTypesssId, setcardTypesssId] = useState<string[]>([]);
    const [cardTypesss, setcardTypesss] = useState<ICardsTypeData[]>([]);
    const [selectAcct, setSelectAcct] = useState<number>();
    const [isSelected, setIsSelected] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [userProfile, setUserProfile] = useState<ILoginData | null>();
    const [cardTypeList, setcardTypeList] = useState<any[]>([]);
    const [iscardTypess, setIscardTypess] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [generateCardList, setGenerateCardList] = useState<ICardsTypeData[]>([
        {
            cardType: 0,
            totalCard: 0,
            cvv: 0,
            cardTypeID: 0

        },
    ]);

    const handleChangeGenerateCard = (
        index: number,
        field: keyof ICardGenerate,
        value: number
    ) => {
        setGenerateCardList((prevGenerateCard) => {
            const updatedGenerateCard = [...prevGenerateCard];
            updatedGenerateCard[index][field] = value;
            return updatedGenerateCard;
        });
    };

    const handleAddGenerateCard = () => {
        setGenerateCardList((prevGenerateCard) => [
            ...prevGenerateCard,
            {
                cardType: 0,
                totalCard: 0,
                cvv: 0,
                cardTypeID: 0

            },
        ]);
    };
    const handleRemoveGenerateCard = (index: number) => {
        setGenerateCardList((prevGenerateCard) => {
            const updatedGenerateCard = [...prevGenerateCard];
            updatedGenerateCard.splice(index, 1);
            return updatedGenerateCard;
        });
    };
    useEffect(() => {
        cardTypeService.getCardTypeList()
            .then((data) => {
                const system_cardTypes: ICardsTypeRes = data
                setcardTypeList(system_cardTypes.data);
                setIsLoading(false)
            })
            .catch((err) => err)
    }, []);
    const handleAccountSelect = (accountId: number) => {
        setSelectAcct(accountId);
        setIsSelected(true)
    };
    const updatecardTypesss = (centres: ICardsTypeData[], cardTypesssId: any[]) => {
        setGenerateCardList(centres);
        setcardTypesssId(cardTypesssId);
    };
    const opencardTypessModal = () => {
        setIscardTypess(true);
        onOpen();
    };
    const removecardTypess = (cardTypess: number) => {
        console.log(cardTypess)
        const ind = cardTypesssId.indexOf(cardTypess.toString());
        console.log(ind)
        cardTypesssId.splice(ind, 1);
        cardTypesss.splice(ind, 1);
        setcardTypesssId([...cardTypesssId]);
        setcardTypesss([...cardTypesss]);
    };

    const handleSubmit = async () => {
        try {
            const submitArray = generateCardList.map(({ cardType, totalCard, cvv }: ICardsTypeData) =>
                ({ cardType, totalCard, cvv }))
            const completed: any[] = [];
            for (let index = 0; index < submitArray.length; index++) {
                const element = submitArray[index];
                const create_acct: any = await cardMasterService.generateCardMaster(element)
                completed.push(create_acct)
            }
           
            if (completed.length = submitArray.length) {
                toast({
                    title: "Card Generation",
                    description: `Cards generated successfully`,
                    status: "success",
                });
                navigate(AdminRoutes.portal.cards)
            } else {
                toast({
                    title: "Card Generation",
                    description: `${completed[0].messages}`,
                    status: "error",
                });
            }
        } catch (error: any) {
            toast({
                title: "Card Generation",
                description: `${error}`,
                status: "error",
            });
        }
    }
    return (
        <>
            {
                isLoading ? <Loading /> :
                    <Box >
                        <Flex
                            justifyContent="space-between"
                            alignItems="cardTypess"
                            pb="20px"
                        >
                            <Box color="#2B2B2B" fontWeight="800" fontSize="24px">
                                Card
                            </Box>

                            <Box
                                mb="20px"
                                display="flex"
                                alignItems="cardTypess"
                                border="1px solid #616161"
                                padding={"5px 12px"}
                                width="fit-content"
                                borderRadius="5px"
                                cursor="pointer"
                            >
                                <HiArrowNarrowLeft color="#616161" fontSize="20px" />
                                <Text
                                    fontSize="14px"
                                    color="#616161"
                                    pl="5px"
                                    onClick={() => navigate(AdminRoutes.portal.cards)}
                                    fontWeight={700}
                                >
                                    Back to Cards
                                </Text>
                            </Box>
                        </Flex>

                        <Box mx={'auto'} mt='40px' width={'600px'} boxShadow={'base'} borderRadius={'15px'} py='20px' bg={'white'} px={'40px'}>
                            <Flex justifyContent={'space-between'} mb={'10px'}>
                                <Text mb={'20px'} color="#353535" fontSize={'16px'} fontWeight={'600'}>
                                    Selected Card Types
                                </Text>
                                <Button
                                    color={"white"}
                                    bg={"#011F78"}
                                    fontSize={'12px'}
                                    size={'sm'}
                                    onClick={() => { opencardTypessModal() }}
                                >
                                    Add Card Type
                                </Button>
                            </Flex>
                            <Flex px={'20px'} justifyContent={'space-between'} borderBottom={'2px solid'} borderColor={'lightgray'}>
                                <Text mb={'10px'} color="#A1A1A1" fontSize={'14px'} fontWeight={'500'}>
                                    Card Type
                                </Text>
                                <Text mb={'10px'} color="#A1A1A1" fontSize={'14px'} fontWeight={'500'}>
                                    Quantity
                                </Text>
                                <Text mb={'10px'} color="#A1A1A1" fontSize={'14px'} fontWeight={'500'}>
                                    CVV
                                </Text>
                            </Flex>

                            <Box
                                mt={'20px'}
                                mb="20px"
                            >
                                {
                                    generateCardList && generateCardList.length > 0 && generateCardList[0].cardTypeName ? generateCardList.map((each, index) => (
                                        <Flex
                                            bg="white"

                                            py={'4px'}
                                            alignItems={'center'}
                                            mb={["5px", "5px", "5px", "5px"]}
                                            flexDirection={["column", "column", "row", "row"]}
                                            mt={'0px'}
                                            justifyContent={'space-between'}
                                            borderBottom={'2px solid'}
                                            borderColor={'lightgrey'}
                                            key={index}
                                        >
                                            <Flex pb={3} pt={1} alignItems={'center'} >
                                                <Flex alignItems={'center'} color="#011F78" >
                                                    <Img width={'50px'} src={each.cardTypeName == "Collo Saving" ? collo : each.cardTypeName == 'Gift Card' ? gift : pocket} />
                                                </Flex>
                                                <Text width={'200px'} fontSize={'12px'}>{each.cardTypeName}</Text>
                                                <FormControl>
                                                    <Input
                                                        borderColor="white"
                                                        placeholder="cardType"
                                                        width={'40px'}
                                                        color={'white'}
                                                        name="cardType"
                                                        value={each.cardTypeID}
                                                        onChange={(event) =>
                                                            handleChangeGenerateCard(
                                                                index,
                                                                "cardType",
                                                                parseInt(event.target.value)
                                                            )
                                                        }
                                                        fontSize={"12px"}
                                                        type="text"
                                                    />

                                                </FormControl>

                                            </Flex>

                                            <FormControl mr={'100px'}>

                                                <Input
                                                    bg="inputText.500"
                                                    placeholder="Quantity"
                                                    name="totalCard"
                                                    width={'90px'}

                                                    value={each.totalCard}
                                                    onChange={(event) =>
                                                        handleChangeGenerateCard(
                                                            index,
                                                            "totalCard",
                                                            parseInt(event.target.value)
                                                        )
                                                    }
                                                    fontSize={"12px"}
                                                    type="number"
                                                />

                                            </FormControl>
                                            <FormControl>

                                                <Input
                                                    bg="inputText.500"
                                                    placeholder="CVV"
                                                    width={'70px'}
                                                    name="cvv"
                                                    value={each.cvv}
                                                    onChange={(event) =>
                                                        handleChangeGenerateCard(
                                                            index,
                                                            "cvv",
                                                            parseInt(event.target.value)
                                                        )
                                                    }
                                                    fontSize={"12px"}
                                                    type="number"
                                                />

                                            </FormControl>
                                        </Flex>


                                    )) : <Box>No data</Box>
                                }
                            </Box>
                            <HStack>
                                <Button
                                    width={'50%'}
                                    variant={'outline'}
                                    colorScheme="blue"
                                    fontSize={'13px'}
                                    type="button"


                                >
                                    Cancel
                                </Button>
                                <Button

                                    color={"white"}
                                    width={'50%'}
                                    bg={"#011F78"}
                                    fontSize={'13px'}
                                    type="button"
                                    //isDisabled={isSelected == false}
                                    onClick={() => { handleSubmit() }}
                                >
                                    Create
                                </Button>
                            </HStack>
                        </Box>
                        {iscardTypess && (
                            <Portal>
                                {" "}
                                <CardsTypeModal
                                    isOpencardTypess={isOpen}
                                    onClosecardTypess={onClose}
                                    iscardTypess={iscardTypess}
                                    updatecardTypesss={updatecardTypesss}
                                    cardTypesss={cardTypesss}
                                    cardTypesssId={cardTypesssId}
                                />{" "}
                            </Portal>
                        )}
                    </Box>

            }
        </>
    )
}

export default NewCard;