import getService from "../crud-requests/getService";
import postService from "../crud-requests/postService";



const CONFIG_API_URL = process.env.REACT_APP_API_BASE_URL ;

class CardMasterService {
    async getCardMasterBatchList() {
      const response = await getService.get(
        `${CONFIG_API_URL}CardMaster/BatchList`,    
      );        
      return response.data;
    }   
    async getCardMasterDetail(cardNumber : string | undefined) {
        const response = await getService.get(
          `${CONFIG_API_URL}CardMaster/Detail/${cardNumber}`,    
        );        
        return response.data;
      }   
      async getCardListByBatch(id : string | undefined) {
        const response = await getService.get(
          `${CONFIG_API_URL}CardMaster/Batch/${id}`,    
        );        
        return response.data;
      } 
      async generateCardMaster(data: any) {
        const response = await postService.post(
          `${CONFIG_API_URL}CardMaster/GenerateCards`,
          data
        );
        return response.data;
      }  
  }
  
  export default new CardMasterService();