import getService from "../crud-requests/getService";
import postService from "../crud-requests/postService";
import putService from "../crud-requests/putService";



const CONFIG_API_URL = process.env.REACT_APP_API_BASE_URL;

class UserService {
    async getUserList() {
        const response = await getService.get(
            `${CONFIG_API_URL}User/List`,
        );
        return response.data;
    }
    async getUserDetail(key: string | undefined) {
        const response = await getService.get(
            `${CONFIG_API_URL}User/Detail/${key}`,
        );
        return response.data;
    }
    
    async createUser(data: any) {
        const response = await postService.post(
            `${CONFIG_API_URL}User/CreateUser`,
            data
        );
        return response.data;
    }
    async editUser(data: any) {
        const response = await putService.put(
            `${CONFIG_API_URL}User/EditUser`,
            data
        );
        return response.data;
    }
}

export default new UserService();