import {
    Box,
    Flex,
    HStack,
    Button,
    InputGroup,
    Input,
    InputRightElement,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Avatar,
    Text,
    MenuItem,
    Menu,
    MenuButton,
    MenuList,
    Center,
    useToast,
    Portal,
    Select,
} from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";
import { RiSearch2Line } from "react-icons/ri";
import { BsThreeDotsVertical } from "react-icons/bs";
import { NumericFormat } from "react-number-format";
import { usePagination } from "@ajna/pagination";
import { useState, useRef, useEffect, ChangeEvent } from "react";
import { Ipage } from "../../models/auth/login.model";
import userService from "../../services/user/user.service";
import { CreateUserModal } from "../../components/users/createUserModal";
import { IUserData, IUserRes } from "../../models/users/users.model";
import { getFullDate } from "../../utils/getDate";
import Loading from "../../components/Loading";
import PaginationData from "../../components/PaginationData";
import { EditUserModal } from "../../components/users/editUserModal";

const User = () => {
    let navigate = useNavigate();
    const toast = useToast();
    const [search, setSearch] = useState("");
    const [query, setQuery] = useState("");
    const [page, setPage] = useState<Ipage>({
        page_total: 0,
        total: 0,
        page_count: 0,
    });
    const [systemuserList, setSystemuserList] = useState<IUserData[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [reload, setReload] = useState(false);
    const [isEditUser, setisEditUser] = useState(false);
    const [isUser, setisUser] = useState(false);
    const [user, setuser] = useState<IUserData>();
    const [userName, setuserName] = useState<string>();

    const cancelRef = useRef(null);

    useEffect(() => {
        // let url = `?pageNumber=${currentPage}&pageSize=${100}&searchString=${query}`
        userService
            .getUserList()
            .then((data) => {
                const system_user: IUserRes = data;
                setSystemuserList(system_user.data);
                console.log(system_user.data, "system user");
                setIsLoading(false);
            })
            .catch((err) => err);
    }, [reload,]);


    const openUser = () => {
        setisEditUser(false);
        setisUser(true);

    };
    const openEditUser = (param: IUserData) => {
        setuser(param);
        setisEditUser(true);
        setisUser(false);

    };

    const reloadData = () => {
        setReload(!reload);
    };
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");
    const filteredUserList = systemuserList.filter((batch) =>
        batch.username.toString().toLowerCase().includes(searchQuery.toString())
        || batch.lastName.toString().toLowerCase().includes(searchQuery.toString())
        || batch.firstName.toString().toLowerCase().includes(searchQuery.toString())
        || batch.email.toString().toLowerCase().includes(searchQuery.toString())
    );
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredUserList.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredUserList.length / itemsPerPage);


    const handleItemsPerPageChange = (newItemsPerPage: number) => {
        setItemsPerPage(newItemsPerPage);
        setCurrentPage(1);
    };

    const handlePageChange = (pageNumber: number) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };
    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when the search query changes
    };
    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <Box>
                    <Flex justifyContent="space-between" alignItems="center" py="15px">
                        <Box color="#2B2B2B" fontWeight="800" fontSize="27px">
                            User
                        </Box>
                        <HStack>
                            <Button
                                cursor="pointer"
                                color={"white"}
                                bg={"#011F78"}
                                type="submit"
                                fontSize={"13px"}
                                onClick={() => {
                                    openUser();
                                }}
                            >
                                Create User
                            </Button>
                        </HStack>
                    </Flex>
                    <Flex py="15px" justifyContent="">
                        <InputGroup bg="white" width="30%" mr="20px">
                            <Input
                                height="40px"
                                placeholder="Search input here"
                                fontSize={12}
                                value={searchQuery}
                                onChange={handleSearchChange}
                                size="sm"
                            />
                            <InputRightElement
                                pointerEvents="none"
                                children={<RiSearch2Line color="green" size="0.9rem" />}
                            />
                        </InputGroup>
                    </Flex>
                    <Box width={"100%"}>
                        <TableContainer>
                            <Table size={"sm"}>
                                <Thead py={"15px"}>
                                    <Tr bg="white" py={"15px"}>
                                        <Td py={"15px"} textTransform={"capitalize"}>
                                            User
                                        </Td>
                                        <Td textTransform={"capitalize"}>Username </Td>
                                        <Td textTransform={"capitalize"}>Email</Td>
                                        <Td textTransform={"capitalize"}>Role</Td>
                                        <Td textTransform={"capitalize"}>Date Created</Td>
                                        <Td textTransform={"capitalize"}>Created By</Td>
                                        <Td textTransform={"capitalize"}>Actions</Td>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {currentItems &&
                                        currentItems.length > 0 &&
                                        currentItems.map((user, index) => (
                                            <Tr bg="white" key={index}>
                                                <Td>
                                                    <HStack spacing="5px" mt={"10px"}>
                                                        <Avatar
                                                            size="sm"
                                                            name={`${user.firstName} ${user.lastName}`}
                                                        />
                                                        <Box>
                                                            <Text
                                                                fontWeight="500"
                                                                textTransform="capitalize"
                                                                fontSize="13px"
                                                            >
                                                                {`${user.firstName} ${user.lastName}`}
                                                            </Text>
                                                        </Box>
                                                    </HStack>
                                                </Td>

                                                <Td>
                                                    <Text fontSize={"13px"}>{user.username}</Text>
                                                </Td>
                                                <Td>
                                                    <Text fontSize={"13px"}>{user.email}</Text>
                                                </Td>
                                                <Td>
                                                    <Text fontSize={"13px"}>{user.role}</Text>
                                                </Td>
                                                <Td>
                                                    <Text fontSize={"13px"}>
                                                        {getFullDate(user.dateCreated)}
                                                    </Text>
                                                </Td>
                                                <Td>
                                                    <Text fontSize={"13px"}>{user.createdBy}</Text>
                                                </Td>

                                                <Td>
                                                    <Button
                                                        cursor="pointer"
                                                        color={"white"}
                                                        bg={"#011F78"}
                                                        size={"sm"}
                                                        fontSize={"13px"}
                                                        onClick={() => { openEditUser(user) }}
                                                    >
                                                        Edit
                                                    </Button>
                                                </Td>
                                            </Tr>
                                        ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                        <Flex justifyContent={'flex-end'} mt={'10px'}>
                            <HStack mr={'5px'}>
                                <Text fontWeight={400} fontSize={'10px'}>Rows per page :</Text>
                                <Select
                                    variant="outline"
                                    size="sm"
                                    width={'70px'}
                                    value={itemsPerPage}
                                    onChange={(e) => handleItemsPerPageChange(Number(e.target.value))}
                                    fontSize={12}
                                >
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>

                                </Select>

                            </HStack>
                            <PaginationData
                                currentPage={currentPage}
                                totalPages={totalPages}
                                handlePageChange={handlePageChange}
                            />

                        </Flex>

                    </Box>
                    {isUser && (
                        <Portal>
                            {" "}
                            <CreateUserModal
                                isOpenUser={isUser}
                                onClosed={setisUser}
                                isUser={isUser}
                                reload={reloadData}
                            />{" "}
                        </Portal>
                    )}

                    {isEditUser && (
                        <Portal>
                            {" "}
                            <EditUserModal
                                isOpenUser={isEditUser}
                                onClosed={setisEditUser}
                                isUser={isEditUser}
                                reload={reloadData}
                                user={user}
                            />{" "}
                        </Portal>
                    )}
                </Box>
            )}
        </>
    );
};

export default User;
