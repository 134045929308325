import { Box, Flex, HStack, Button, InputGroup, Input, InputRightElement, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Avatar, Text, MenuItem, Menu, MenuButton, MenuList, Center, useToast, Portal, Select } from '@chakra-ui/react'

import { useNavigate } from 'react-router-dom';
import { RiSearch2Line } from 'react-icons/ri';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { NumericFormat } from 'react-number-format';
import { usePagination } from '@ajna/pagination';
import { useState, useRef, useEffect, ChangeEvent } from 'react';
import { Ipage } from '../../models/auth/login.model';
import userService from '../../services/user/user.service';
import { CreateUserModal } from '../../components/users/createUserModal';
import { IUserData, IUserRes } from '../../models/users/users.model';
import { getFullDate } from '../../utils/getDate';
import { CreateClientModal } from '../../components/clients/createClientModal';
import cleintAccessService from '../../services/clientAccess/cleint-access.service';
import { IClientResult, IClientsRes } from '../../models/clients/clients.model';
import Loading from '../../components/Loading';
import PaginationData from '../../components/PaginationData';

const Clients = () => {
    let navigate = useNavigate();
    const toast = useToast()
    const [search, setSearch] = useState("");
    const [query, setQuery] = useState("");
    const [page, setPage] = useState<Ipage>({ page_total: 0, total: 0, page_count: 0 });
    const [systemclientList, setSystemclientList] = useState<IClientResult[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [reload, setReload] = useState(false);
    const [isEditclient, setisEditclient] = useState(false);
    const [isclient, setisclient] = useState(false);
    const [client, setclient] = useState<any>();
    const [clientName, setclientName] = useState<string>();
  
    useEffect(() => {
        // let url = `?pageNumber=${currentPage}&pageSize=${100}&searchString=${query}`
        cleintAccessService.getClientAccessList()
            .then((data) => {
                const system_client: IClientsRes = data
                //setPage({ page_total: system_param.pageSize, total: system_param.totalCount, page_count: system_param.totalPages })
                setSystemclientList(system_client.data.result);
                setIsLoading(false)
            })
            .catch((err) => err)

    }, [ query, reload]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");
    const filteredSystemClientList = systemclientList.filter((batch) =>
        batch.clientName.toString().toLowerCase().includes(searchQuery.toString()) || batch.clientKey.toString().toLowerCase().includes(searchQuery.toString())

    );
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredSystemClientList.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredSystemClientList.length / itemsPerPage);


    const handleItemsPerPageChange = (newItemsPerPage: number) => {
        setItemsPerPage(newItemsPerPage);
        setCurrentPage(1);
    };

    const handlePageChange = (pageNumber: number) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };
    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when the search query changes
    };
    
   
    const openclient = () => {
        setisEditclient(false)
        setisclient(true)
      

    };
    const openEditclient = (param: any) => {
        setclient(param)
        setisEditclient(true)
        setisclient(false)
      

    };

    const reloadData = () => {
        setReload(!reload);
    };


    return (
        <>
          {
                isLoading ? <Loading /> :
            <Box >
                <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    py="15px"
                >
                    <Box color="#2B2B2B" fontWeight="800" fontSize="27px">
                        Clients
                    </Box>
                    <HStack>
                        <Button
                            cursor="pointer"
                            color={"white"}
                            bg={"#011F78"}
                            type="submit"
                            fontSize={'13px'}
                            onClick={() => { openclient() }}
                        >
                            Create client
                        </Button>
                    </HStack>
                </Flex>
                <Flex py='15px' justifyContent=''>
                    <InputGroup bg="white" width="30%" mr='20px'>
                        <Input height='40px' placeholder="Search input here" fontSize={12}  value={searchQuery}
                                    onChange={handleSearchChange} size="sm" />
                        <InputRightElement pointerEvents="none" children={<RiSearch2Line color="blue" size="0.9rem" />} />
                    </InputGroup>

                </Flex>
                <Box width={'100%'}>
                    <TableContainer>
                        <Table >
                            <Thead py={'15px'}>
                                <Tr bg="white" py={'15px'} >
                                    <Td py={'15px'} textTransform={'capitalize'}>Client Name</Td>
                                    <Td textTransform={'capitalize'}>Client Id </Td>

                                    <Td textTransform={'capitalize'}>Client Key</Td>
                                    <Td textTransform={'capitalize'}>Date Created</Td>
                                    <Td textTransform={'capitalize'}>Actions</Td>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {
                                    currentItems && currentItems.length > 0 && (
                                        currentItems.map((client, index) => (
                                            <Tr bg="white" key={index}>

                                                <Td>
                                                    <HStack spacing='5px' mt={'10px'}>
                                                        <Avatar
                                                            size="sm"
                                                            name={`${client.clientName}`}
                                                        />
                                                        <Box>
                                                            <Text fontWeight='500' textTransform='capitalize' fontSize='13px' >
                                                                {`${client.clientName}`}
                                                            </Text>
                                                        </Box>
                                                    </HStack>
                                                </Td>

                                                <Td>
                                                    <Text fontSize={'13px'} >{client.clientID}</Text>
                                                </Td>
                                                <Td>
                                                    <Text fontSize={'13px'} >{client.clientKey}</Text>
                                                </Td>

                                                <Td>
                                                    <Text fontSize={'13px'} >{getFullDate(client.dateCreated)}</Text>
                                                </Td>

                                                <Td>
                                                    <Button
                                                        cursor="pointer"
                                                        color={"white"}
                                                        bg={"#011F78"}
                                                        size={'sm'}
                                                        fontSize={'13px'}

                                                    >
                                                        Edit
                                                    </Button>
                                                </Td>
                                            </Tr>
                                        ))
                                    )
                                }
                            </Tbody>
                        </Table>
                    </TableContainer>
                    <Flex justifyContent={'flex-end'} mt={'10px'}>
                            <HStack mr={'5px'}>
                                <Text fontWeight={400} fontSize={'10px'}>Rows per page :</Text>
                                <Select
                                    variant="outline"
                                    size="sm"
                                    width={'70px'}
                                    value={itemsPerPage}
                                    onChange={(e) => handleItemsPerPageChange(Number(e.target.value))}
                                    fontSize={12}
                                >
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>

                                </Select>

                            </HStack>
                            <PaginationData
                                currentPage={currentPage}
                                totalPages={totalPages}
                                handlePageChange={handlePageChange}
                            />

                        </Flex>

                </Box>
                {isclient && (
                    <Portal>
                        {" "}
                        <CreateClientModal
                            isOpenClient={isclient}
                            onClosed={setisclient}
                            isClient={isclient}
                            reload={reloadData}
                        />{" "}
                    </Portal>
                )}
            </Box>
}
        </>
    )
}

export default Clients;