import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    Box,
    useToast,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Flex,
    Button,
    HStack,
    Textarea,
    Select,
    Portal,
    Checkbox,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { IRegRes } from "../../models/auth/registration.model";
import AdminRoutes from "../../pages/routes";
import { useNavigate } from "react-router-dom";
import rolesService from "../../services/roles/roles.service";
import cleintAccessService from "../../services/clientAccess/cleint-access.service";
import cardTypeService from "../../services/cardType/card-type.service";

export const EditCardTypeModal: React.FC<any> = ({
    isOpencardType,
    onClosed,
    isEditcardTypes = false,
    reload,
    card
}: {
    isEditcardTypes: boolean;
    isOpencardType: any;
    onClosed: any;
    reload: any;
    card: any
}) => {
    const toast = useToast();
    const cancelRef = useRef().current;
    const navigate = useNavigate()
    const [isTransactional, setisTransactional] = useState(false)
    const [hasPin, setIsPin] = useState(false)
    const [isoneTime, setIsOnetime] = useState(false)

    useEffect(() => {
        if (card) {
            formik.setValues({
                name: card.cardTypeName,
                prefix: card.prefix,
                costPrice : card.costPrice
            })
            card.isTransactional ? setisTransactional(true) : setisTransactional(false)
            card.hasPin ? setIsPin(true) : setIsPin(false)
            card.oneTimeUse ? setIsOnetime(true) : setIsOnetime(false)
        }
    }, []);
    const formik = useFormik({
        validate: (values: any) => {
            const errors: any = {};
            if (!values.name) {
                errors.name = "Enter name";
            }
            if (!values.prefix) {
                errors.prefix = "enter prefix";
            }
            return errors;
        },
        initialValues: {
            name: "",
            prefix: "",
            costPrice: ""
        },
        onSubmit: async (values) => {
            try {
                const new_param: any = {
                    cardTypeID: card.cardTypeID,
                    cardTypeName: values.name,
                    prefix: parseInt(values.prefix),
                    hasPin: hasPin,
                    isTransactional: isTransactional,
                    oneTimeUse: isoneTime,
                    costPrice: parseInt(values.costPrice),
                    
                }
                const create_param: any = await cardTypeService.editCardType(new_param)
                console.log(create_param.data)
                if (create_param.data) {
                    toast({
                        title: "Card Type",
                        description: "card type created successfully",
                        status: "success",
                    });
                    onClosed(!isEditcardTypes)
                    reload()
                    navigate(AdminRoutes.portal.cardsType)
                } else {
                    toast({
                        title: "Card Type",
                        description: `${create_param.error}`,
                        status: "error",
                    });
                }
            } catch (error: any) {
                toast({
                    title: "Card Type",
                    description: `${error}`,
                    status: "error",
                });
            }
        }
    });

    return (
        <>
            <Modal
                isOpen={isOpencardType && isEditcardTypes}
                onClose={onClosed}
                scrollBehavior="outside"
                closeOnOverlayClick={true}
                size="lg"
            >
                <ModalOverlay />
                <ModalContent borderRadius={5} px={6}>
                    <ModalHeader>
                        {
                            <Text
                                fontSize={'18px'}
                                pb={3}
                                textTransform="capitalize"
                            >
                                Card Type
                            </Text>
                        }
                    </ModalHeader>
                    <ModalCloseButton size="sm" />
                    <ModalBody pb={'30px'}>
                        <Box mt={2}>
                            <form onSubmit={formik.handleSubmit}>
                                <FormControl
                                    mb={'20px'}
                                    isInvalid={
                                        formik.errors.name && formik.touched.name
                                            ? true
                                            : false
                                    }
                                >
                                    <FormLabel
                                        fontWeight="normal"
                                        color="brand.3"
                                        fontSize={13}
                                    >
                                        Name
                                    </FormLabel>
                                    <Input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={formik.values.name}
                                        placeholder="Enter name"
                                        onChange={formik.handleChange}
                                        fontSize={13}
                                    />
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.name}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl
                                    mt={3}
                                    isInvalid={
                                        formik.errors.prefix && formik.touched.prefix ? true : false
                                    }
                                >
                                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                        Prefix
                                    </FormLabel>
                                    <Input
                                        type="text"
                                        id="prefix"
                                        name="prefix"
                                        value={formik.values.prefix}
                                        placeholder="Enter prefix"
                                        onChange={formik.handleChange}
                                        fontSize={13}
                                    />
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.prefix}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl
                                    mt={3}
                                    isInvalid={
                                        formik.errors.prefix && formik.touched.prefix ? true : false
                                    }
                                >
                                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                        Cost Price
                                    </FormLabel>
                                    <Input
                                        type="text"
                                        id="costPrice"
                                        name="costPrice"
                                        placeholder="Enter price"
                                        value={formik.values.costPrice}
                                        onChange={formik.handleChange}
                                        fontSize={13}
                                    />
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.costPrice}
                                    </FormErrorMessage>
                                </FormControl>

                                <HStack spacing={'10px'} mt={'20px'}>
                                    <Checkbox fontSize={'12px'} isChecked={isTransactional} color={'#575757'} onChange={(e) => setisTransactional(e.target.checked)} colorScheme='blue' >
                                        <Text fontSize={'13px'}>  Is Transactional</Text>
                                    </Checkbox>
                                    <Checkbox fontSize={'12px'} isChecked={hasPin} color={'#575757'} onChange={(e) => setIsPin(e.target.checked)} colorScheme='blue' >
                                        <Text fontSize={'13px'}>    Has Pin</Text>
                                    </Checkbox>
                                    <Checkbox fontSize={'12px'} isChecked={isoneTime} color={'#575757'} onChange={(e) => setIsOnetime(e.target.checked)} colorScheme='blue' >
                                        <Text fontSize={'13px'}>   Is One Time Use</Text>
                                    </Checkbox>

                                </HStack>


                                <Flex mt={12} justifyContent={'space-between'} >
                                    <Button
                                        px={6}
                                        colorScheme="blue"
                                        fontSize={'14px'}
                                        variant={'outline'}
                                        ref={cancelRef}
                                        width={'49%'}
                                        onClick={() => onClosed()}
                                    >
                                        Cancel
                                    </Button>
                                    <Button

                                        type="submit"
                                        isLoading={formik.isSubmitting}
                                        color={"white"}
                                        width={'49%'}
                                        bg={"#011F78"}
                                        px={6}
                                        fontSize={'14px'}
                                    >
                                        Confirm
                                    </Button>


                                </Flex>
                            </form>
                        </Box>

                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
