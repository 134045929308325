import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    Box,
    useToast,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Flex,
    Button,
    HStack,
    Textarea,
    Center,
    InputGroup,
    InputLeftElement,
    InputRightElement,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import userService from "../../services/user/user.service";
import { BiHide, BiShow } from "react-icons/bi";
import { MdLock } from "react-icons/md";
import rolesService from "../../services/roles/roles.service";
import { IRolesRes } from "../../models/roles/roles.model";
import Select from 'react-select';
export const CreateUserModal: React.FC<any> = ({
    isOpenUser,
    onClosed,
    isUser = false,
    reload
}: {
    isUser: boolean;
    isOpenUser: any;
    onClosed: any;
    reload: any;
}) => {
    const toast = useToast();
    const cancelRef = useRef().current;
    const [show, setShow] = useState(false)
    const [roles, setRoles] = useState<any>()
    const [isLoading, setIsLoading] = useState(true)
    const [selectedRole, setselectedRole] = useState<any>(null);
    const [rolesList, setrolesList] = useState<any[]>();
    const handleClick = () => setShow(!show)

    useEffect(() => {
        rolesService.getRoleList()
            .then((data) => {
                let rolesArray: any[] = []
                const roles: IRolesRes = data
                roles.data.forEach(element => {
                    rolesArray.push({ value: element.roleCode, label: element.roleName })
                });

                setrolesList(rolesArray)
                setIsLoading(false)
            })
            .catch((err) => err)
    }, [reload]);
    const handleChangeRoles = (selectedRole: any) => {
        setselectedRole(selectedRole);
    };
    const formik = useFormik({
        validate: (values: any) => {
            const errors: any = {};
            if (!values.username) {
                errors.username = "username is required";
            }
            if (!values.email) {
                errors.email = "email is required";
            }

            return errors;
        },
        initialValues: {
            username: "",
            email: "",
            firstName: "",
            lastName: "",
            password: "",
            role: ""
        },
        onSubmit: async (values) => {
            try {
                const new_param: any = {
                    username: values.username,
                    email: values.email,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    role: selectedRole.value,
                    password: values.password

                }

                const create_User: any = await userService.createUser(new_param)
                console.log(create_User.data)
                if (create_User.data) {
                    toast({
                        title: "Create User",
                        description: "User created successfully",
                        status: "success",
                    });
                    onClosed(!isUser)
                    reload()
                } else {
                    toast({
                        title: "Create User",
                        description: `${create_User.messages.$values[0]}`,
                        status: "error",
                    });
                }
            } catch (error: any) {
                toast({
                    title: "Create User",
                    description: `${error}`,
                    status: "error",
                });
            }

        },
    });

    return (
        <>
            <Modal
                isOpen={isOpenUser && isUser}
                onClose={onClosed}
                scrollBehavior="outside"
                closeOnOverlayClick={true}
                size="lg"
            >
                <ModalOverlay />
                <ModalContent borderRadius={5} px={6}>
                    <ModalHeader>
                        {
                            <Text
                                fontSize={'18px'}
                                pb={3}
                                textTransform="capitalize"


                            >
                                Create User
                            </Text>
                        }
                    </ModalHeader>
                    <ModalCloseButton size="sm" />
                    <ModalBody pb={'30px'}>
                        <Box mt={2}>
                            <form onSubmit={formik.handleSubmit}>
                                <HStack>
                                    <FormControl
                                        mt={3}
                                        isInvalid={
                                            formik.errors.username && formik.touched.username ? true : false
                                        }
                                    >
                                        <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                            First Name
                                        </FormLabel>
                                        <Input
                                            type="text"
                                            id="firstName"
                                            name="firstName"
                                            placeholder="Enter first name"
                                            onChange={formik.handleChange}
                                            fontSize={'12px'}
                                        />
                                        <FormErrorMessage fontSize={12}>
                                            {formik.errors.firstName}
                                        </FormErrorMessage>
                                    </FormControl>
                                    <FormControl
                                        mt={3}
                                        isInvalid={
                                            formik.errors.username && formik.touched.username ? true : false
                                        }
                                    >
                                        <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                            Last Name
                                        </FormLabel>
                                        <Input
                                            type="text"
                                            id="lastName"
                                            name="lastName"
                                            placeholder="Enter last name"
                                            onChange={formik.handleChange}
                                            fontSize={'12px'}
                                        />
                                        <FormErrorMessage fontSize={12}>
                                            {formik.errors.lastName}
                                        </FormErrorMessage>
                                    </FormControl>
                                </HStack>
                                <HStack>
                                    <FormControl
                                        mt={3}
                                        isInvalid={
                                            formik.errors.username && formik.touched.username ? true : false
                                        }
                                    >
                                        <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                            Username
                                        </FormLabel>
                                        <Input
                                            type="text"
                                            id="username"
                                            name="username"
                                            placeholder="Enter username"
                                            onChange={formik.handleChange}
                                            fontSize={'12px'}
                                        />
                                        <FormErrorMessage fontSize={12}>
                                            {formik.errors.username}
                                        </FormErrorMessage>
                                    </FormControl>
                                    <FormControl mt={3} isInvalid={(formik.errors.role && formik.touched.role) ? true : false} >
                                        <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                            Role
                                        </FormLabel>
                                        <Select
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: state.isFocused ? '#D6D6D6' : '#D6D6D6',
                                                    fontSize: '12px'
                                                }),
                                            }}
                                            value={selectedRole}
                                            onChange={handleChangeRoles}
                                            options={rolesList}
                                            isSearchable={true}
                                            placeholder={'select role...'}
                                        />
                                    </FormControl>
                                </HStack>


                                <HStack>
                                    <FormControl
                                        mt={3}
                                        isInvalid={
                                            formik.errors.email && formik.touched.email ? true : false
                                        }
                                    >
                                        <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                            Email
                                        </FormLabel>
                                        <Input
                                            type="email"
                                            id="email"
                                            name="email"
                                            placeholder="Enter email"
                                            onChange={formik.handleChange}
                                            fontSize={'12px'}
                                        />
                                        <FormErrorMessage fontSize={12}>
                                            {formik.errors.email}
                                        </FormErrorMessage>
                                    </FormControl>
                                    <FormControl
                                         mt={3}
                                        isInvalid={
                                            formik.errors.password && formik.touched.password
                                                ? true
                                                : false
                                        }
                                    >
                                        <FormLabel color="brand.subtitle" fontSize={13}>
                                            Password{" "}
                                        </FormLabel>
                                        <InputGroup>
                                            <InputLeftElement
                                                color="brand.subtitle"
                                                pointerEvents="none"
                                                children={<MdLock />}
                                            />
                                            <Input
                                              
                                                type={show ? 'text' : 'password'}
                                                name="password"
                                                id="password"
                                                placeholder="Enter  password"
                                                onChange={formik.handleChange}
                                                fontSize={'12px'}
                                            />
                                            <InputRightElement width='4.5rem'>
                                                <Center cursor={'pointer'} h='1.75rem' onClick={handleClick}>
                                                    {show ? <BiHide /> : <BiShow />}
                                                </Center>
                                            </InputRightElement>
                                        </InputGroup>
                                        <FormErrorMessage fontSize={12}>
                                            {formik.errors.password}
                                        </FormErrorMessage>
                                    </FormControl>
                                </HStack>

                                <Flex mt={12} justifyContent={'space-between'} >


                                    <Button

                                        px={6}
                                        colorScheme="blue"
                                        fontSize={'14px'}
                                        variant={'outline'}
                                        ref={cancelRef}
                                        width={'49%'}
                                        onClick={() => onClosed()}
                                    >
                                        Cancel
                                    </Button>
                                    <Button

                                        type="submit"
                                        isLoading={formik.isSubmitting}
                                        color={"white"}
                                        width={'49%'}
                                        bg={"#011F78"}
                                        px={6}
                                        fontSize={'14px'}
                                    >
                                        Confirm
                                    </Button>


                                </Flex>
                            </form>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
