import {
    Box,
    Button,
    Center,
    Flex,
    FormControl,
    FormErrorMessage,
    Text,
    FormLabel,
    Input,
    InputGroup,
    InputLeftElement,
    useToast,
    Link,
    HStack,
    Image,
    Stack,
    InputRightElement,
    Portal,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { MdLock } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import AuthLayout from "./AuthLayout";
import AdminRoutes from "../routes";
import authService from "../../services/auth/auth.service";
import { ILoginRes } from "../../models/auth/login.model";
import { useEffect, useState } from "react";
import { BiHide, BiShow } from "react-icons/bi";
import { VerifyEmailModal } from "../../components/auth/VerifyEmailModal";



const Login = () => {
    const route = useNavigate();
    const toast = useToast();
    const [show, setShow] = useState(false)
    const [isGenerate, setisGenerate] = useState(false);
    const [parameter, setParameter] = useState<any>();
    const handleClick = () => setShow(!show)
   
    const openGenerateCodeModal = (userId: string) => {
        setParameter(userId)
        setisGenerate(true)

    };
    const validate = (values: any) => {
        const errors: any = {};
        if (!values.username) {
            errors.username = "username is required";
        }
        if (!values.password) {
            errors.password = "Password is required";
        }
        return errors;
    };
   

    const formik = useFormik({
        initialValues: { username: "", password: "" },
        validate,
        onSubmit: async (values) => {
            try {
                const data = {
                    username: values.username,
                    password: values.password,
                }
                const login_user: ILoginRes = await authService.login(data)

                if (login_user.statusCode == 200) {
                    route(AdminRoutes.portal.dashboard)
                    sessionStorage.setItem('card_user_token', login_user.data.token)
                    sessionStorage.setItem('card_user_user', JSON.stringify(login_user.data.details))
                    toast({
                        title: "Login",
                        description: `user successfully logged in`,
                        status: "success",
                    });
                  
                } else if (login_user.statusCode == 230){
                    openGenerateCodeModal('')
                } else {
                    toast({
                        title: "Login",
                        description: `${login_user.error}`,
                        status: "error",
                    });
                }
            } catch (error: any) {
                toast({
                    title: "Login",
                    description: `${error}`,
                    status: "error",
                });
            }
        },
    });


    return (
        <>
            <AuthLayout>
                <Flex mx="auto" className="resizable_div" height="95vh">
                    <Box >
                        <Box
                            ml={["30px", "30px", "60px", "60px"]}
                            mr={["30px", "30px", "0px", "0px"]}
                            mt={'100px'}
                            mb="15px"
                        >


                            <Box mb={'40px'}>
                                <Text
                                    mb={'10px'}
                                    fontSize={["20px", "20px", "24px", "24px"]}
                                    fontWeight="700"
                                    color="#011F78"
                                >
                                    Login
                                </Text>
                                <Text

                                    fontSize={["10px", "10px", "14px", "14px"]}
                                    fontWeight="400"
                                    color="#90979E"
                                >
                                    Enter username and password to access your account
                                </Text>
                            </Box>


                            <form onSubmit={formik.handleSubmit}>
                                <FormControl
                                    mb="20px"
                                    isInvalid={
                                        formik.errors.username && formik.touched.username ? true : false
                                    }
                                >
                                    <FormLabel color="#757575" fontSize={15}>
                                        Username

                                    </FormLabel>


                                    <Input
                                        w={["300px", "300px", "520px", "520px"]}
                                        type="text"
                                        id="username"
                                        name="username"
                                        placeholder="Enter username"
                                        onChange={formik.handleChange}
                                        fontSize={14}
                                    />


                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.username}
                                    </FormErrorMessage>
                                </FormControl>

                                <FormControl
                                    mb="20px"
                                    isInvalid={
                                        formik.errors.password && formik.touched.password
                                            ? true
                                            : false
                                    }
                                >
                                    <FormLabel color="brand.subtitle" fontSize={15}>
                                        Password{" "}

                                    </FormLabel>
                                    <InputGroup>
                                        <InputLeftElement
                                            color="brand.subtitle"
                                            pointerEvents="none"
                                            children={<MdLock />}
                                        />
                                        <Input
                                            w={["100%", "100%", "520px", "520px"]}
                                            type={show ? 'text' : 'password'}
                                            name="password"
                                            id="password"
                                            placeholder="Enter  password"
                                            onChange={formik.handleChange}
                                            fontSize={14}
                                        />
                                        <InputRightElement width='4.5rem'>
                                            <Center cursor={'pointer'} h='1.75rem' onClick={handleClick}>
                                                {show ? <BiHide /> : <BiShow />}
                                            </Center>
                                        </InputRightElement>
                                    </InputGroup>
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.password}
                                    </FormErrorMessage>
                                </FormControl>
                                <Flex justifyContent={'flex-end'}>
                                   
                                    <Center >
                                        <Box mb={2} fontWeight={'600'} alignSelf="center" color="#011F78" fontSize={14}>
                                            <Link onClick={() => { route(AdminRoutes.forgotPassword) }}>Forgot Password?</Link>
                                        </Box>
                                    </Center>
                                </Flex>

                                <Stack spacing="12px">
                                    <Button
                                        cursor="pointer"
                                        w="100%"
                                        color={"white"}
                                        bg={"#011F78"}
                                        type="submit"
                                        isLoading={formik.isSubmitting}
                                        fontSize={14}
                                    >
                                        Login
                                    </Button>
                                </Stack>
                            </form>
                        </Box>
                        {isGenerate && (
                            <Portal>
                                {" "}
                                <VerifyEmailModal
                                    isOpen={isGenerate}
                                    onClosed={setisGenerate}
                                    isVerify={isGenerate}
                                    username={'updatecardTypesss'}
                                  
                                />{" "}
                            </Portal>
                        )}
                    </Box>
                
                </Flex>

            </AuthLayout>
        </>
    );
};

export default Login;
