import { Box, Flex, HStack, Button, InputGroup, Input, InputRightElement, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Avatar, Text, MenuItem, Menu, MenuButton, MenuList, Center, useDisclosure, Checkbox, Select } from '@chakra-ui/react'

import { useNavigate, useParams } from 'react-router-dom';
import { RiSearch2Line } from 'react-icons/ri';
import { BsCheckLg, BsDot, BsThreeDotsVertical } from 'react-icons/bs';
import { NumericFormat } from 'react-number-format';
import { usePagination } from '@ajna/pagination';
import { useState, useEffect, ChangeEvent } from 'react';
import AdminRoutes from '../routes';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import Loading from '../../components/Loading';
import cardMasterService from '../../services/cardMaster/card-master.service';
import { ICardBatch, ICardBatchRes } from '../../models/cards/card-batch.model';
import { FaTimes } from 'react-icons/fa';
import PaginationData from '../../components/PaginationData';

const CardsByBatch = () => {
    let navigate = useNavigate();
    const { batch_id } = useParams();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [cardBatchList, setcardBatchList] = useState<ICardBatch[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [reload, setReload] = useState(false);


    useEffect(() => {

        if (batch_id) {
            const url = `${batch_id}`
            cardMasterService.getCardListByBatch(url)
                .then((data) => {
                    const agents: ICardBatchRes = data
                    setcardBatchList(agents.data);
                    setIsLoading(false)
                })
                .catch((err) => err)
        }
    }, [reload]);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");
    const filteredCardBatchList = cardBatchList.filter((batch) =>
        batch.cardKey.toLowerCase().includes(searchQuery) || batch.cardType.toString().toLowerCase().includes(searchQuery.toString())

    );

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredCardBatchList.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredCardBatchList.length / itemsPerPage);


    const handleItemsPerPageChange = (newItemsPerPage: number) => {
        setItemsPerPage(newItemsPerPage);
        setCurrentPage(1);
    };

    const handlePageChange = (pageNumber: number) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };
    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when the search query changes
    };

    return (
        <>
            {
                isLoading ? <Loading /> :
                    <Box >
                        <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            py="26px"
                        >
                            <Box color="#2B2B2B" fontWeight="800" fontSize="27px">
                                Batch Details  : {batch_id}
                            </Box>
                            <Box
                                mb="20px"
                                display="flex"
                                alignItems="center"
                                border="1px solid #616161"
                                padding={"5px 12px"}
                                width="fit-content"
                                borderRadius="5px"
                                cursor="pointer"
                            >
                                <HiArrowNarrowLeft color="#616161" fontSize="20px" />
                                <Text
                                    fontSize="13px"
                                    color="#616161"
                                    pl="5px"
                                    onClick={() => navigate(AdminRoutes.portal.cards)}
                                    fontWeight={'500'}
                                >
                                    Back to batch list
                                </Text>
                            </Box>
                        </Flex>
                        <Flex py='15px' justifyContent=''>
                            <InputGroup bg="white" width="30%" mr='20px'>
                                <Input height='40px' placeholder="Search input here" value={searchQuery}
                                    onChange={handleSearchChange} fontSize={12} size="sm" />
                                <InputRightElement pointerEvents="none" children={<RiSearch2Line color="blue" size="0.9rem" />} />
                            </InputGroup>

                        </Flex>
                        <Box width={'100%'}>
                            <TableContainer>
                                <Table size={'sm'}>
                                    <Thead py={'15px'}>
                                        <Tr bg="white" py={'15px'} >
                                            <Td py={'15px'} textTransform={'capitalize'}>Card Key</Td>
                                            <Td py={'15px'} textTransform={'capitalize'}>Card Type</Td>
                                            <Td py={'15px'} textTransform={'capitalize'}>CVV</Td>
                                            <Td textTransform={'capitalize'}>Expiry Date</Td>
                                            <Td textTransform={'capitalize'}>Is Activated</Td>
                                            <Td textTransform={'capitalize'}>Is Printed</Td>
                                            <Td textTransform={'capitalize'}>Status</Td>

                                        </Tr>
                                    </Thead>
                                    <Tbody>

                                        {
                                            currentItems && currentItems.length > 0 ? currentItems.map((card, index) => (
                                                <Tr key={index} bg="white" >
                                                    <Td>
                                                        <Text fontSize={'14px'}>{card.cardKey}</Text>
                                                    </Td>
                                                    <Td>
                                                        <Text fontSize={'14px'}>{card.cardType}</Text>
                                                    </Td>
                                                    <Td>
                                                        <Text fontSize={'14px'}>{card.cvv}</Text>
                                                    </Td>

                                                    <Td>
                                                        <Text fontSize={'14px'}>{`${card.monthExpire}, ${card.yearExpire}`}</Text>
                                                    </Td>
                                                    <Td>
                                                        {card.isActivated ? <Text fontSize={'14px'} ><BsCheckLg color='green' /></Text> :
                                                            <Text fontSize={'14px'} ><FaTimes color='red' /></Text>}
                                                    </Td>
                                                    <Td>
                                                        {card.isPrinted ? <Text fontSize={'14px'} ><BsCheckLg color='green' /></Text> :
                                                            <Text fontSize={'14px'} ><FaTimes color='red' /></Text>}
                                                    </Td>
                                                    <Td>{
                                                        card.status == 'ACTIVE' ? <HStack><BsDot fontSize={'20px'} color='green' />
                                                            <Text fontSize={'14px'} textTransform={'capitalize'}>{card.status}</Text>
                                                        </HStack> :
                                                            <HStack><BsDot fontSize={'20px'} color='red' />
                                                                <Text fontSize={'14px'} textTransform={'capitalize'}>{card.status}</Text>
                                                            </HStack>
                                                    }


                                                    </Td>



                                                </Tr>
                                            )) : <Box>No data</Box>
                                        }

                                    </Tbody>
                                </Table>
                            </TableContainer>
                            <Flex justifyContent={'flex-end'} mt={'10px'}>
                                <HStack mr={'5px'}>
                                    <Text fontWeight={400} fontSize={'10px'}>Rows per page :</Text>
                                    <Select
                                        variant="outline"
                                        size="sm"
                                        width={'70px'}
                                        value={itemsPerPage}
                                        onChange={(e) => handleItemsPerPageChange(Number(e.target.value))}
                                        fontSize={12}
                                    >
                                        <option value={10}>10</option>
                                        <option value={25}>25</option>
                                        <option value={50}>50</option>

                                    </Select>

                                </HStack>
                                <PaginationData
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    handlePageChange={handlePageChange}
                                />

                            </Flex>

                        </Box>
                    </Box>
            }
        </>

    )
}

export default CardsByBatch;