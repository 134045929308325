import { Flex, Image, HStack, Box, Text, Button, useToast, FormControl, FormLabel, Input } from '@chakra-ui/react';
import logo from '../../img/CallnPay.svg';
import congrats from '../../img/congrats.svg';
import OTPInput from "react-otp-input";
import { useState } from 'react'
import authService from '../../services/auth/auth.service';
import { useNavigate } from 'react-router-dom';
import AdminRoutes from '../routes';
const EmailSent = () => {
    const toast = useToast()
    const route = useNavigate()
    const [username, setUsername] = useState<string>()
    const [code, setCode] = useState<string>()
    const [
        { otp, numInputs, separator, minLength, maxLength, placeholder, inputType },
        setConfig,
    ] = useState({
        otp: "",
        numInputs: 6,
        separator: "--",
        minLength: 0,
        maxLength: 40,
        placeholder: "",
        inputType: "number" as const,
    });
    const handleOTPChange = (otp: string) => {
        setConfig((prevConfig) => ({ ...prevConfig, otp }));
    };
    const handleSubmit = async () => {

        try {
            const data = {
                userName: username,
                code: code,
            }



            const generate_code: any = await authService.login(data)
            console.log(generate_code.succeeded)
            if (generate_code.succeeded) {
                toast({
                    title: "Verify Email",
                    description: `user account confirmed successfully`,
                    status: "success",
                });
                //     route(AdminRoutes.Login)

            } else {
                toast({
                    title: "Verify Email",
                    description: `${generate_code.messages.$values[0]}`,
                    status: "error",
                });
            }

        } catch (error: any) {
            toast({
                title: "Verify Email",
                description: `${error}`,
                status: "error",
            });
        }
    };

    return (
        <>
            <Box>
                <Box py='30px' px={'20px'}>
                    <Image src={logo} />
                </Box>
                <Flex height={'70vh'} alignItems={'center'} justifyContent={'center'} width={'100%'}>
                    <Box width={'365px'}>
                        <Flex justifyContent={'center'}>
                            <Image src={congrats} />
                        </Flex>
                        <Box mt={'10px'} mb={'20px'}>
                            <Text
                                mb={'5px'}
                                fontSize={["20px", "20px", "24px", "24px"]}
                                fontWeight="700"
                                color="#353535"
                                textAlign={'center'}

                            >
                                Verify Email
                            </Text>
                            <Text
                                fontSize={["10px", "10px", "12px", "12px"]}
                                fontWeight="400"
                                mb={'10px'}
                                color="#90979E"
                                textAlign={'center'}
                            >
                                An email has been sent to you, 
                                verify your account by clicking on the link in the mail
                                 or entering the OTP sent to your mail. thank you
                            </Text>
                            <Box>
                                <FormControl
                                    mb="20px"

                                >

                                    <Input

                                        type="text"
                                        id="username"
                                        name="username"
                                        placeholder="Enter Username"
                                        onChange={(e) => { setUsername(e.target.value) }}
                                        fontSize={'13px'}
                                        value={username}
                                    />


                                </FormControl>
                                <FormControl
                                    mb="20px"

                                >

                                    <Input

                                        type="text"
                                        id="code"
                                        name="code"
                                        placeholder="Enter code"
                                        onChange={(e) => { setCode(e.target.value) }}
                                        fontSize={'13px'}
                                        value={code}
                                    />


                                </FormControl>


                                <Flex justifyContent={'space-between'} >

                                    <Button
                                        type="button"
                                        color={"white"}
                                        width={'100%'}
                                        bg={"#011F78"}
                                        px={6}
                                        onClick={() => { handleSubmit() }}
                                        fontSize={'14px'}
                                    >
                                        Verify Email
                                    </Button>
                                </Flex>
                            </Box>


                        </Box>

                    </Box>
                </Flex>
            </Box>

        </>
    )
}

export default EmailSent