import { Flex, Image, HStack, Box, Text } from '@chakra-ui/react';
import logo from '../../img/CallnPay.svg';

const AuthLayout = (props: any) => {

    return (
        <Flex height="100vh" >
            <Box
                display={["none", "none", "block", "block"]}
                width={["0%", "0%", "30%", "30%"]}
                height="100vh"
                bg={'#EDF1FF69'}
                px={'40px'}
                py={'50px'}
            >
                <Box>
                    <Image src={logo} />
                </Box>
                <Box mt={'80px'}>
                    <Text mb={'25px'} fontWeight={'700'} fontSize={'28px'} color={'#011F78'}>
                        Card Management System
                    </Text>
                    <Text fontWeight={'400'} fontSize={'16px'} color={'#90979E'}>
                        Create and Monitor all <br /> card  activities
                    </Text>
                </Box>
            </Box>
            <Box width={'70%'} pl={{ base: "5em", lg: "10em", xl: "10em" }} pr={{ base: "0em", lg: "8em", xl: "8em" }} mt={10}>
                {props.children}
            </Box>
        </Flex>
    );
}

export default AuthLayout;